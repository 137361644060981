.sidebar {
  width: 200px;
  border-left: var(--border);
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 0;
  justify-content: flex-start;
}

.subscriptionHighlight {
  padding: 0 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  color: red !important;
  font-size: 15px;
}

// .searchBar {
//   border-bottom: var(--border);
//   padding: 5px;
//   display: flex;
//   flex-direction: row;
//   gap: 4px;
//   button {
//     padding: 0 5px;
//   }
// }