
.mainPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px;
}

.title {
  margin-top: 24px;
}

.bookAds {
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
  gap: 48px;
}

.bookAd {
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.bookAdCentered {
  display: flex;
  flex: 1;
  justify-content: center;
  align-content: center;
  align-items: center;
}