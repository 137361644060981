.navbar {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 0px;
  list-style: none;
  background: rgb(233,233,233);
  background: linear-gradient(0deg, rgba(233,233,233,1) 0%, rgba(255,255,255,1) 100%);

  border-bottom: var(--border);
}

.navbarDropdown {
  display: inline-block;
  &:hover .navbarDropdownContent {
    display: flex;
  }
}


.navbarDropdownContent {
  display: none;
  position: absolute;
  z-index: 99999;
  overflow: hidden;
  flex-direction: column;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: var(--border);
  border-top: none;
  background: white;
  
  &:hover {
    display: flex;
  }
  .navbarMenuItem {
    border-right: none;
    padding: 15px 10px;
  }

}
/* TODO: refactor */
:not(.navbarDropdownContent) > .navbarMenuCurrent {
  background-color: #2d2d2d;
  color: white !important;
  & > .navbarMenuItem:not(:hover) {
    color: white;
  }
}

.navbarMenuItem {
  color: black;
  display: flex;
  padding: 10px 30px;
  border-right: var(--border);
  background: none;
  &:hover {
    color: black !important;
    background: url(assets/images/mainnav-hover.gif) repeat-x bottom #f7f7f7;
    // background: linear-gradient(0deg,  rgba(210,210,210,1) 0%, rgba(255,255,255,1) 50%, rgba(210,210,210,1) 100%);
    cursor: pointer;
  }
}

