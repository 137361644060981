article {
  flex-wrap: wrap;
  font-size: 15px;
  ul,ol {
    margin-top: 1em;
    padding-left: 24px;
  }

  table:not(.unstyledTable) {
    border: var(--border);
    td:not(:last-child) {
      border-right: var(--border);
    }
  }

  p,pre:not(:first-child) {
    margin-top: 1em;
  }

  h1,h2,h3:not(:first-child) {
    margin-top: 1em;
  }
}

.footnote {
  vertical-align: super;
  font-size: 60%;
  margin-left: .1em;
}