.infoPanel {
  display: flex;
  flex-direction: column;
  color: #3A505C;
  a {
    color: #3A505C !important;
  }
  & > * {
    border-bottom: var(--border);
    padding-bottom: 10px;
  }
  min-width: 230px;
  max-width: 240px;
}
.infoPanelWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 0;
}