.footer {
  color: #999;
  background-color: #EFEFEF;
  border-top: var(--border);
  padding: 12px;
  padding-right: 24px;
  display: flex;
  flex-direction: row;
  // text-align: right;
  justify-content: space-between;
  // align-items: center;
  font-size: 14px;
}