* {
  margin: 0;
  padding: 0;
}



a:not(:hover), a:hover.forceUnhoverLink {
  &:active {
    color: unset;
  }
  text-decoration: none;
}
.forceLinkUnderline {
  text-decoration: underline !important;
}



a,a:active,a:focus {
  color: #145077;
}

// #root{
//     height:100%;
// }
