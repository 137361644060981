.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
  border-bottom: 1px solid #ddd;
}

.banner {
  // padding: 5px;
}

.subheader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 60px;
  padding: 0;
  font-size: 12px;
  width: 100%;
  list-style: none;

  transform: translateX(-16px);
  .floating {
    display: inline-block;
    flex: 1;
    transform: none;
  }
}