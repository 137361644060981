.tableOfContents {
  display: flex;
  flex-direction: column;
  padding: 12px;
  list-style: none;
  text-align: left;
}


.contentSection {
  padding: 5px 0;
  transition: margin-left .1s ease-in-out;
  position: relative;
  margin-left: 16px;
  &:not(:last-child) {
    border-bottom: var(--border);
  }
  &:hover {
    margin-left: 24px;
  }
  &::before {
    content: '▶';
    position: absolute;
    left: -16px;
    margin-right: 8px;
  }
}
